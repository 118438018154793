/*
 * @NOTE: Prepend a `~` to css file paths that are in your node_modules
 *        See https://github.com/webpack-contrib/sass-loader#imports
 */
/* setup clients page hide flag of phone input 2 */


.report {
  width: 100%;
  margin-bottom: 1rem;
  color: #525f7f;
  background-color: transparent;
}

.report {
  margin-top: 20px;
  margin-bottom: 20px;
  color: #000000 !important;
}

.report>thead>tr>th,
.report>tbody>tr>th,
.report>tfoot>tr>th,
.report>thead>tr>td,
.report>tbody>tr>td,
.report>tfoot>tr>td {
  border-color: rgba(255, 255, 255, 0.1);
  padding: 3px;
  vertical-align: middle;
}

.report>thead>tr>th {
  border-bottom-width: 1px;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 700;
  border: 0;
  color: rgba(255, 255, 255, 0.7);
}

.report thead th {
  // border: 3px solid black !important;
  border: 1px solid #32325d1c !important;
  color: #000000 !important;
  font-size: 10px;
  font-weight: bolder !important;
  border-top: none;
}

.report .report-table-body tr td {
  border: 1px solid #32325d1c !important;
  color: #000000 !important;
  font-size: 9px;
  // font-weight: 600;
  border: none;
}

.sub-content {
  color: #aeacac;
  font-weight: 100;
  font-size: 11px;
}

input[type="number"] {
  appearance: textfield;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
}

.form-control {
  min-height: calc(2.25rem + 2px);
  height: auto !important;
}

.form-check .form-check-label1 {
  display: inline-block;
  // position: relative;
  cursor: pointer;
  padding-left: 25px;
  line-height: 18px;
  margin-bottom: 0;
  -webkit-transition: color 0.3s linear;
  -moz-transition: color 0.3s linear;
  -o-transition: color 0.3s linear;
  -ms-transition: color 0.3s linear;
  transition: color 0.3s linear;
}

//  unit holders add new form
.react-tel-input .flag-dropdown {
  display: none !important;
}

.react-tel-input .form-control {
  border: 1px solid #344675;
}

// end

// single beta txn drop downs
.css-yk16xz-control {
  color: #fff !important;
  background-color: transparent !important;
  border: 1px solid #344675 !important;
}

.css-1uccc91-singleValue {
  color: #1d8cf8 !important;
}

// end

// Dashboard icons
.card-stats .info-icon i {
  color: #fff;
  font-size: 1.7em;
  padding: 14px 13px;
}

.card-stats .info-icon {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background: #e14eca;
  background-image: linear-gradient(to bottom left, #e14eca, #ba54f5, #e14eca);
  background-size: 210% 210%;
  background-position: 100% 0;
}

.card-stats .info-icon.icon-warning {
  background: #ff8d72;
  background-image: linear-gradient(to bottom left, #ff8d72, #ff6491, #ff8d72);
  background-size: 210% 210%;
  background-position: 100% 0;
}

.card-stats .info-icon.icon-success {
  background: #00f2c3;
  background-image: linear-gradient(to bottom left, #00f2c3, #0098f0, #00f2c3);
  background-size: 210% 210%;
  background-position: 100% 0;
}

.card-stats .info-icon.icon-info {
  background: #1d8cf8;
  background-image: linear-gradient(to bottom left, #1d8cf8, #3358f4, #1d8cf8);
  background-size: 210% 210%;
  background-position: 100% 0;
}

// end 

//top navbar logout dropdown start 
.dropdown-item {
  width: 95% !important;
}

// end

// bulk upload preview table
.json-to-table tr:nth-child(even) {
  background-color: transparent !important;
}

// end

input[type=number] {
  text-align: right;
}

.mt-25 {
  margin-top: 25px;
}

.mt-minus6 {
  margin-top: -6px;
}

.json-to-table {
  color: #a2b0d4;

}

.json-to-table table tbody tr td {
  border: 1px solid #4d5b7e;
  padding: 5px;
}


.dot-text {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

// hide up,down arrows in input[type="number"]
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

//  Additional css
.text-silver {
  color: #818dab
}

// .fixed-plugin .dropdown-menu { 
//     z-index: 100; 
// }

.fixed-plugin .dropdown .dropdown-menu:after,
.fixed-plugin .dropdown .dropdown-menu:before {
  top: 26px !important;
  width: 12px !important;
}

.fixed-plugin {
  top: 11px !important;
  height: 40px;
  z-index: 1200 !important;
}

.container-login {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tsparticles-canvas-el {
  position: absolute;
  width: 100vw !important;
  height: 100vh !important;
}


.card-number {
  font-size: 200px;
  position: absolute;
  top: 70px;
  opacity: 0.05;
}


.h-450 {
  min-height: 480px;
}

.h-430 {
  min-height: 400px !important;
}

/**** Scroll ****/
::-webkit-scrollbar {
  width: 8px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.5);
}

.process-card {
  box-shadow: 0px 7px 10px rgba(black, 0.5);

  &:hover {
    transform: translateY(-10px);
    border-top: 4px solid #ba54f5;
    ;
  }
}


.process-card {
  box-shadow: 0px 7px 10px rgba(black, 0.5);
  min-height: 280px;

  &:hover {
    transform: translateY(-10px);
    border-top: 4px solid #ba54f5;
    ;
  }
}


.mt-a {
  margin-top: auto;
}

.inline-box {
  display: -webkit-inline-box;
}

.position-ab {
  position: absolute;
  margin-top: 150px;
}

.full-page>.content {
  padding-bottom: unset !important;
  padding-top: unset !important;
}

.bg-transparent {
  background: unset !important;

  .form-control {
    background-color: hsla(0, 0%, 100%, .8) !important;
  }

  label {
    color: #fff !important;
  }
}

// login
.multi-select {
  --rmsc-main: #1d8cf8 !important;
  --rmsc-border: #2b3553 !important;
  --rmsc-gray: #d3d3d3 !important;
  --rmsc-bg: rgba(0, 0, 0, 0.05) !important;
  --rmsc-p: 10px !important;
  --rmsc-radius: 4px !important;
  --rmsc-h: 38px !important;

  --rmsc-hover: #1d8cf8 !important;
  --rmsc-selected: #ba54f5 !important;

}

img {
  border-radius: unset !important;
}

.card.card-white .form-control:hover {
  border-color: #ba54f5;
}

small {
  font-size: 80% !important;
  font-weight: 400;
}

.position-ab {
  position: absolute;
}

.mt-a {
  margin-top: auto;
}

.inline-box {
  display: -webkit-inline-box;
}

.py-15 {
  margin: 15px 0px;
}

.form-control:hover {
  border-color: #ba54f5;
}

select>option {
  color: black;
}

.form-control {
  background-color: rgba(0, 0, 0, .05) !important;
}


.flex-end {
  display: flex;
  justify-content: flex-end;

}

.pointer {
  cursor: pointer;
}

text-hover:hover {
  color: #1d8cf8;

}

.mt-22 {
  margin-top: 22px;
}

.text-border {
  // border-bottom: 1px dotted #f5f5f5;
  color: white;

  span {
    cursor: pointer;
  }

  span:hover {
    color: #1d8cf8;
  }
}

.modal.show .modal-dialog {
  transform: none !important;
}


.white-content .card:not(.card-white) {
  background: #fff;
  box-shadow: 0 1px 15px 0 hsla(0, 0%, 48.2%, .05) !important;
}

.modal .modal-header .close {
  color: #fd5d93 !important;
  text-shadow: none;
}

.white-content .line {
  width: 100%;
  height: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #333;
}

.text-hover:hover {
  color: #1d8cf8;
}

.box-report:hover {
  box-shadow: 2px 2px 6px rgba(0, 0, 0, .4);
  transform: translateY(-1px);
  -webkit-transform: translateY(-1px);
}

.color-primary {
  color: #ba54f5;
}




.mb-15 {
  margin-bottom: 14px;
}

.space-between {
  justify-content: space-between;
}


// modal according to the screen
.modal-content {
  background-color: #1e1e2f !important;
}

.white-content .modal-content {
  background-color: #fff !important;
}

.modal-content .modal-header .modal-title,
.modal-content .modal-header .title {
  color: #fff !important;
}



.white-content .modal-content .modal-header .modal-title,
.white-content .modal-content .modal-header .title {
  color: #333 !important;
}

.center {
  left: 50%;
  transform: translateX(0%);
}

.pt-7 {
  padding-top: 7px;
}

.fa-popup {
  position: absolute;
  z-index: 100;
  background-color: white !important;
  margin-bottom: 0px;

  input {
    margin-right: 10px;
  }
}

.text-black {
  color: #333 !important;
}



// end

// fuz code

.as-read {
  font-size: 13px;
  margin-top: 4px;
  text-decoration: underline;
  color: #075eff;
  cursor: pointer;
}

.checlist-scroll {
  height: 40vh;
  overflow-y: scroll;
  overflow-x: hidden !important;
}



// @import '~bootstrap/scss/bootstrap';
h2 {
  margin: 0;
  font-size: 2.25rem;
  font-weight: bold;
  letter-spacing: -0.025em;
  color: #fff;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  -webkit-appearance: none !important;
}

.login-font {
  font-size: 24px;
}

.tax-text {
  position: absolute;
  top: 45px;
  color: rgb(255, 255, 255);
}

.tax-bottom-text {
  position: absolute;
  right: 20px;
  top: 55px;
  color: rgb(255, 255, 255);
}

.hight-sx {
  height: 99vh;
}

.body-pad h1 {
  margin-top: 0px !important;
}

// .dropdown-menu {
//     min-width: fit-content !important;
//     background-color: #2e6968 !important;
// }

.url-image-sx {
  width: 100%;
  height: 100%;
}

// #dropdown-basic {
//     background-color: transparent !important;
//     border: none !important;
// }

a {
  text-decoration: none !important;
}

.glow-sp-x {
  background-color: white;
  border: 2px solid black;
  color: black;
}

// .dropdown-item {
//     color: #ffffff !important;
//     font-size: 20px;
//     min-width: fit-content !important;
//     &:hover {
//         color: white;
//         background-color: #2e6968 !important;
//     }
// }

// .multi-select {
//     --rmsc-main:#181f1c !important;
//     --rmsc-border:#000 !important;
//     --rmsc-gray: #aaa;
//     --rmsc-bg: #fff;
//     --rmsc-p: 10px; /* Spacing */
//     --rmsc-radius: 4px; /* Radius */
//     --rmsc-h: 38px; /* Height */
//   }





li {
  list-style: none;
}

.hide-overflow {
  overflow: hidden !important;
}

a {
  // color: white;
  // opacity: 0.75;
  text-decoration: none;
}

a:hover {
  opacity: 1;
  text-decoration: none;
  cursor: pointer;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.code-pattern {
  position: absolute;
  font-size: 10px;
  // color: gray;
  color: #b0fdfd;
  left: -20px;
  bottom: -15px;
}

.error-message {
  position: absolute;
  right: 0px !important;
  bottom: -17px !important;
}

.network-box {
  //  border:2px solid red;
  position: absolute;
  right: 20px;
  margin-top: 70px;
  padding: 15px;
  color: white;
  background-color: rgba(253, 28, 28, 0.8);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.info-text {
  font-size: 1rem;
}

// body {
//     margin: 0 !important;
//     background-color: #003b3a !important;
//     font-family: 'El Messiri', sans-serif !important;
//     color: #b0fdfd !important;
//     font-size: 18px !important;
//     -webkit-font-smoothing: antialiased !important;
//     -moz-osx-font-smoothing: grayscale !important;
//     --reactAppMenu-color-text: #b0fdfd !important;
//     --reactAppMenu-color-bg-hover: #027e80 !important;
//     --reactAppMenu-color-bg: #555555 !important;
//     --reactAppMenu-color-bg-focus: #00989b !important;
//     --reactAppMenu-color-border: none !important;
//     --reactAppMenu-color-bg-hover: #006666 !important;
// }

.reactAppMenubar,
.navbar {
  background: #464646;
  margin-left: -15px !important;
  margin-right: -15px !important;
}

.navbar {
  height: 68px;
}

.reactAppMenubar--menu--labelContainer {
  padding: 10px 30px !important;
}

.reactAppMenubar--menu-root {
  font-size: 16px;
}

.navbar-brand {
  font-size: 20px;
  font-weight: 600;
  color: #b8b8b8 !important;
  margin-left: 30px;

  p {
    margin-bottom: -7px;
  }
}

p {
  margin-bottom: 0px;
}

.bg-1 {
  background-color: #003b3a;
}

.bg-2 {
  background-color: #054e4e;
}

.bg-3 {
  background-color: #b0fdfd;
}

.bg-positive {
  background-color: #5ba2a3 !important;
}

.bg-negative {
  background-color: #32cacc !important;
}

.color-white {
  color: #b0fdfd;
}

.color-grey {
  color: #003b3a;
}

.color-amber {
  color: orange;
}

.fill-height {
  height: 100vh;
}

.t-1 {
  font-size: 24px;
  font-weight: 600;
}

.trustee-text {
  font-size: 16px;
}

.t-2 {
  font-size: 36px;
  font-weight: 600;
}

.reason-input {
  height: 35px;
  background-color: #b0fdfd;
  color: #003b3a;
  font-size: 15px;
}

.select-text {
  font-size: 20px;
  font-weight: 500;
}

.input-1 {
  position: relative;
  height: 52px;
  width: 310px;
  background-color: #b0fdfd;
  color: #003b3a;
  margin-top: 10px;
  margin-bottom: 15px;
}

.btn-1 {
  width: 155px;
  height: 52px;
  color: #b0fdfd;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  cursor: pointer;

  p {
    margin-bottom: -7px;
  }

  .login-text {
    margin-bottom: 5px;
  }
}

.btn-2 {
  width: 310px;
  height: 52px;
  color: #b0fdfd;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  margin-bottom: 16px;
  cursor: pointer;

  p {
    margin-bottom: -7px;
  }
}

.t-3 {
  font-size: 14px;
}

.login-holder {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  :hover {
    background-color: #b0fdfd !important;
    color: rgb(0, 153, 153) !important;
  }
}

a {
  // color: inherit;
  text-decoration: none !important;
}

a:hover {
  color: inherit;
  text-decoration: none;
}

.overlay {
  background: rgba(61, 51, 51, 0.6);
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 20;
  visibility: visible;
  opacity: 1;
  transition: all 0.6s ease-in;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hidden {
  visibility: hidden;
  opacity: 0;
  transition: all 0.6s ease-in;
}

.popup {
  background-color: rgb(0, 102, 102);
  box-shadow: 0px 6px 60px 0px rgba(0, 0, 0, 0.2);
  width: 543px;
  height: 383px;
  padding: 48px 90px;
  text-align: center;

  .btn-holder {
    margin-top: 61px;
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .digit-holder {
    margin-top: 50px;
    width: 304px;
    display: flex;
    justify-content: space-between;
  }

  .input-2 {
    background-color: #b0fdfd;
    width: 42px;
    height: 52px;
  }
}

.t-4 {
  font-size: 30px;
  font-weight: 600;
}

h1 {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: -7px;
  margin: 0px !important;
}

.login-input {
  height: 43px !important;
}

.login-section {
  position: relative;
}

.login-err {
  position: absolute;
  right: 0px !important;
  top: 51px !important;
}

.nav-item {
  margin-left: 16px;
  margin-right: 16px;
}

.body-pad {
  padding: 42px;
}

.col-xs-15,
.col-sm-15,
.col-md-15,
.col-lg-15 {
  position: relative;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px;
}

.col-xs-15 {
  width: 10%;
  float: left;
}

@media (min-width: 768px) {
  .col-sm-15 {
    width: 10%;
    float: left;
  }
}

@media (min-width: 992px) {
  .col-md-15 {
    width: 10%;
    float: left;
  }
}

@media (min-width: 1200px) {
  .col-lg-15 {
    width: 10%;
    float: left;
  }
}

.stat-row {
  margin-top: 30px;
}

.filter-box {
  display: flex;
  width: 100%;
  justify-content: start;
  font-size: 16px;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

.pass-err {
  position: absolute;
  right: 0px !important;
  top: 60px !important;
}

.input-3 {
  width: 250px;
  height: 42px;
  background-color: #e7e7e7;
  display: flex;
  justify-content: space-between;
  border-radius: 6px;
  align-items: center;

  .date {
    width: 50px;
    height: 42px;
    background-color: #555555;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.drop-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  height: 90px;
  color: rgba(53, 189, 212, 0.924);
  border: 2px dashed #4aa1f3;
}

.width-half {
  width: 500px;
}

.label-width {
  width: 200px;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.upload-icon {
  font-size: 30px;
}

.a4-page {
  // position: absolute;
  min-width: 680px;
  background-color: white;
  color: black;
  min-height: 842px;
}

.fields-section {
  min-width: 300px;
  min-height: 842px;
}

.formats-section {
  min-width: 300px;
  min-height: 842px;
}

.btn-group {
  display: flex;
  justify-content: center;
}

.ml-px {
  margin-left: 70px;
}

.placeholder-box {
  width: 100%;
  height: fit-content;
  background-image: linear-gradient(90deg, rgb(0, 169, 171) 0%, #055a5a);
  margin-top: 35px;
  border-radius: 14px;
}

.bar-chart {
  width: 100%;
  height: 300px;
  margin-left: 50px;
  margin-top: 35px;
}

.form-holder {
  // background-color: #006666;
  background: #31595a;
  margin-top: 25px;
  padding: 45px;
}

.table-empty {
  font-size: 100px;
  width: 100%;
  margin-top: 100px;
  margin-bottom: 150px;

  .icon {
    font-size: 100px;
    width: 100%;
  }
}

.empty-text {
  font-size: 15px;
}

.loading-icon {
  display: flex;
  justify-content: center;
}

h3 {
  font-size: 20px;
  font-weight: 600;
}

.special-acc {
  color: #0f3b04;
  font-weight: bolder !important;
}

.search-icon {
  font-size: 30px;
  position: absolute;
  top: 19px;
  left: 12px;
  border-radius: 5px;
  background-color: rgb(0, 105, 106);
  padding: 5px;
}

.txn-size {
  font-size: 16px;
}

.security-scroll {
  height: 60vh;
  overflow-y: scroll;
  overflow-x: hidden !important;
}

.required-border {
  border: 2px solid #fb3d3d !important;
  box-shadow: 0 4px 8px 0 rgba(255, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.69) !important;
}

.txn-box-sm-px {
  background-image: linear-gradient(90deg, rgb(0, 169, 171) 0%, #055a5a);
  width: 100%;
  min-height: 400px !important;
  max-height: fit-content;
  color: white;
  font-weight: 600;
  cursor: pointer;
  border-radius: 7px;
  box-shadow: 80px;
  margin-top: 17px;

  .title {
    font-size: 20px;
    padding-top: 10px;
  }

  .value {
    font-size: 36px;
  }

  p {
    margin-bottom: -5px;
  }

  .border-bottom-sm {
    border-bottom: 2px solid rgb(57, 237, 253);
    background: #ffffff6e;
    color: black;
    border-radius: 7px 7px 0px 0px;
    /* border-radius: 40px; */
    border-bottom: 2px solid #39edfd;
  }
}

.radio-holder {
  width: 250px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  label {
    margin-left: 20px;
    margin-bottom: -4px;
  }
}

.modal-margin {
  padding-right: 200px !important;

  .modal-content {
    width: 130% !important;
  }
}

.input-bro {
  margin-bottom: 20px !important;
}

.box-element {
  height: 25px;
  padding: 11px;
  border: 1px solid #000808;
}

.rotate-icon {
  display: none;
}

.cross-cheque {
  padding-top: 10px;
  padding-bottom: 10px;
  border-top: 2px solid black;
  border-bottom: 2px solid black;
}

.rotate-parent:hover {
  .rotate-icon {
    display: inline-block;
  }
}

.item-psxs {
  width: 130px;
}

.box-items {
  display: flex;
  justify-content: center;
}

.cross-icon {
  font-size: 23px;
  margin-left: -2rem;
  cursor: pointer;
}

.show>.btn-success.dropdown-toggle {
  color: #006666 !important;
}

.icon-pos-xpt {
  position: absolute !important;
  right: 0px !important;
}

.input-holder {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;

  .dropdown {

    button,
    html [type=button] {
      -webkit-appearance: button;
      width: 200px !important;
      height: 42px !important;
      background-color: #b0fdfd !important;
      margin: 0px !important;
      display: flex !important;
      justify-content: space-between !important;
      align-items: center !important;
      font-size: 16px !important;
      font-weight: 600 !important;
      color: #006666 !important;
    }

    .input-1 {
      height: 52px !important;
      width: 310px !important;
      background-color: #b0fdfd !important;
      color: #003b3a !important;
      margin-top: 10px !important;
      margin-bottom: 15px !important;
    }
  }

  // .dropdown-item {
  //     color: #006666 !important;
  //     background-color: #b0fdfd !important;
  //     .form-check {
  //         display: flex !important;
  //     }
  // }
  // .dropdown-item:hover {
  //     color: #006666 !important;
  //     background-color: #b0fdfd !important;
  // }
  .drop-item {
    width: 250px;
    color: #006666 !important;
    background-color: #b0fdfd !important;
  }

  .drop-item:hover {
    color: #006666 !important;
    background-color: #b0fdfd !important;
  }

  .label {
    font-size: 16px;
    font-weight: 600;
  }

  .input-broker {
    width: 421px !important;
  }

  .input-1 {
    width: 250px;
    height: 42px;
    background-color: #b0fdfd;
    margin: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    color: #006666;

    p {
      margin-left: 20px;
    }

    .icon {
      width: 50px;
      height: 42px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .date {
      width: 50px;
      height: 42px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #a5efef;
    }
  }

  .input-2 {
    width: 170px;
    height: 42px;
    background-color: #b0fdfd;
    margin: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    color: #006666;

    p {
      margin-left: 20px;
      margin-bottom: -5px;
    }

    .icon {
      width: 50px;
      height: 42px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #a5efef;
    }
  }

  .multi-input {
    width: 250px;
    height: 42px;
    display: flex;
    justify-content: space-between;

    .input-2 {
      width: 200px;
      height: 30px !important;
      background-color: #b0fdfd;
    }

    .input-sign {
      width: 200px;
      height: 42px !important;
      background-color: #b0fdfd;
    }

    .icon {
      width: 50px;
      height: 42px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.MuiMenuItem-root {
  padding-top: 0px !important;
}

.txn-box-sx-px {
  background-image: linear-gradient(90deg, rgb(0, 169, 171) 0%, #055a5a);
  width: 100%;
  min-height: 520px !important;
  max-height: fit-content;
  color: white;
  font-weight: 600;
  cursor: pointer;
  border-radius: 9px;
  box-shadow: 80px;
  margin-top: 17px;

  .title {
    font-size: 20px;
    padding-top: 10px;
  }

  .value {
    font-size: 36px;
  }

  p {
    margin-bottom: -5px;
  }

  .border-bottom-sm {
    border-bottom: 2px solid rgb(57, 237, 253);
    background: #ffffff6e;
    color: black;
    border-radius: 9px 9px 0px 0px;
    /* border-radius: 40px; */
    border-bottom: 2px solid #39edfd;
  }
}

.left {
  padding-right: 50px;
}

.right {
  padding-left: 50px;
}

.title-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-content: flex-end;
}

.btn-3 {
  // background-color: #208282;
  background: linear-gradient(90deg, #00a9ab 0%, #055a5a);
  width: 155px;
  height: 52px;
  display: flex;
  outline: none !important;
  justify-content: center;
  align-items: center;
  margin-top: 40px;

  p {
    margin-bottom: -5px;
  }
}

.btn-update-password {
  width: 13rem !important;

  p {
    margin-bottom: 1px !important;
  }
}

.my-table thead th {
  color: #b0fdfd;
  font-size: 16px;
  font-weight: bold;
  border-top: none;
}

// .report{
//   margin-top: 20px;
//   margin-bottom: 20px;

// }
// .report thead th {
//   border: 3px solid black !important;
//   color: #000000;
//   font-size: 10px;
//   font-weight: bolder !important;
//   border-top: none;
// }
// .report tr td {
//   border: 1px solid black !important;
//   color: #000000;
//   font-size: 10px;
//   font-weight: 600;
//   border: none;
// }

.my-table tr td {
  color: #b0fdfd;
  font-size: 16px;
  font-weight: 600;
  border: none;
}

.center {
  text-align: center;
}

.cursor:focus {
  box-shadow: none !important;
  outline: 0 none !important;
  border: none !important;
}

.crown-icon {
  left: -15px;
  top: 14px;
  color: rgb(226, 187, 12);
}

.heading-noti {
  font-weight: bold;
}

.tick-img {
  position: absolute;
  right: 7px;
  top: 14px;
}

.tab-color {
  background-color: #1f9997
}

.txn-box-lg-px {
  background-image: linear-gradient(90deg, rgb(9, 84, 85) 0%, #134d4d);
  width: 100%;
  height: 500px !important;
  color: white;
  font-weight: 600;
  cursor: pointer;
  border-radius: 8px;
  box-shadow: 80px;
  margin-top: 17px;

  .title {
    font-size: 20px;
    padding-top: 10px;
  }

  .value {
    font-size: 36px;
  }

  p {
    margin-bottom: -5px;
  }

  .border-bottom-sm {
    border-bottom: 2px solid rgb(57, 237, 253);
    background: #31595a;
    color: rgb(255, 255, 255);
    border-radius: 8px 8px 0px 0px;
    /* border-radius: 40px; */
    border-bottom: 2px solid #39edfd;
  }

  .input-size-pos {
    padding: 0px 20px 0px 20px;
  }
}

.width-right-sm {
  width: 46% !important;
  margin-right: 10px;
}

.form-holder-lg {
  width: 100%;
  background-color: #181f1c;
  margin-top: 25px;
  border-radius: 10px;
  padding: 45px;
}

.bank-sm {
  position: absolute;
  right: 40%;
  top: -25px;
  font-size: 16px;
}

.bell-icon-dot {
  position: absolute;
  top: 19px;
  color: white;
  font-size: 11px;
}

.text-noti {
  font-size: 16px;
  word-spacing: 1px;
  letter-spacing: 1px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
}

.line-height {
  line-height: 1;
}

.date-noti {
  font-size: 13px;
}

.tick-noti {
  color: #e14eca;
  font-size: 22px;
}

.unread-noti {
  background-color: #27293d !important;
}

.cross-noti {
  color: rgb(148, 34, 34);
}

.multi-icons {
  display: flex;

  img {
    margin: 0px 6px;
  }
}

.box {
  padding: 10px 15px;
  background-color: #464646;
  margin-bottom: 30px;

  .title {
    font-size: 18px;
    font-weight: 600;
  }
}

.add-btn {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 10px;
}

.btn-4 {
  // background-color: #208282;
  background: linear-gradient(90deg, #00a9ab 0%, #055a5a);
  width: 155px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    margin-bottom: -5px;
  }

  cursor: pointer;
}

.user-header {
  width: 50%;
  position: relative;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  color: white;
  cursor: pointer;
}

.selected-option {
  background-color: #003b3a;
}

.setting-title {
  font-size: 20px;
  font-weight: 600;
  margin-top: 40px;
}

.description-box {
  height: 125px !important;
}

.line {
  width: 100%;
  height: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid white;
}

label {
  font-size: 16px;
}

.txn-box {
  background-color: #5ba2a3;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  width: 100%;
  height: 104px;
  cursor: pointer;
}

.txn-box:hover {
  background-color: #4e898a;
}

.comment-box {
  width: 250px;
  height: 100px;
  background-color: white;
  font-size: 14px;
  padding: 5px 10px;

  p {
    margin-bottom: 0px !important;
    font-weight: 600;
    color: #006666;
  }
}

.auth-box {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to top, rgb(0, 105, 106) 0%, rgb(81, 172, 173)100%);
  padding: 30px;
  text-align: center;
  color: white;

  p {
    margin-bottom: 0px;
  }

  .title {
    font-size: 18px;
  }

  .small {
    font-size: 16px;
  }

  .accepted {
    color: #32cd32;
    font-size: 24px;
    font-weight: bold;
  }

  .rejected {
    color: #cd3232;
    font-size: 24px;
    font-weight: bold;
  }

  .pending {
    color: #5432cd;
    font-size: 24px;
    font-weight: bold;
  }

  .comment {
    font-size: 16px;
    font-weight: bold;
  }
}

.txn-box-pg-px {
  background-image: linear-gradient(90deg, rgb(9, 84, 85) 0%, #134d4d);
  width: 100%;
  min-height: 500px;
  height: fit-content;
  color: white;
  font-weight: 600;
  cursor: pointer;
  border-radius: 14px;
  box-shadow: 80px;
  margin-top: 17px;

  .title {
    font-size: 20px;
    padding-top: 10px;
  }

  .value {
    font-size: 36px;
  }

  p {
    margin-bottom: -5px;
  }

  .border-bottom-sm {
    border-bottom: 2px solid rgb(57, 237, 253);
    background: #31595a;
    color: rgb(255, 255, 255);
    border-radius: 12px 12px 0px 0px;
    /* border-radius: 40px; */
    border-bottom: 2px solid #39edfd;
  }

  .input-size-pos {
    padding: 0px 20px 0px 20px;
  }
}

// body {
//     margin: 0 !important;
//     background-color: #003b3a !important;
//     font-family: 'El Messiri', sans-serif !important;
//     color: #b0fdfd !important;
//     font-size: 18px !important;
//     -webkit-font-smoothing: antialiased !important;
//     -moz-osx-font-smoothing: grayscale !important;
//     --reactAppMenu-color-text: #b0fdfd !important;
//     --reactAppMenu-color-bg-hover: #027e80 !important;
//     --reactAppMenu-color-bg: #555555 !important;
//     --reactAppMenu-color-bg-focus: #00989b !important;
//     --reactAppMenu-color-border: none !important;
//     --reactAppMenu-color-bg-hover: #006666 !important;
// }

.reactAppMenubar,
.navbar {
  background: #464646;
  margin-left: -15px !important;
  margin-right: -15px !important;
}

.navbar {
  height: 68px;
}

// textarea:focus,
// input[type="password"]:focus,
// input[type="text"]:focus,
// input[type="datetime"]:focus,
// input[type="datetime-local"]:focus,
// input[type="date"]:focus,
// input[type="month"]:focus,
// input[type="time"]:focus,
// input[type="week"]:focus,
// input[type="number"]:focus,
// input[type="email"]:focus,
// input[type="url"]:focus,
// input[type="search"]:focus,
// input[type="tel"]:focus,
// input[type="checkbox"]:focus,
// input[type="color"]:focus,
// select:focus,
// button[type="button"]:focus,
// button[type="button"]:hover,
// .uneditable-input:focus {
//     box-shadow: none !important;
//     outline: 0 none !important;
//     border: none !important;
// }

input[type="search"]::placeholder {
  padding-left: 10px !important;
}

// .modal-margin-top {
//     margin-top: 12rem !important;
// }

// .modal-content {
//     background-color: #003b3a !important;
// }

// .modal-view {
//     margin: auto !important;
//     color: white;
//     padding: 3rem;
// }

// .modal-checklist-view {
//     color: white;
//     padding: 3rem;
// }

@media (min-width: 992px) {
  .modal-lg {
    width: fit-content;
    max-width: 1200px;
  }
}


.form-view-holder {
  background-color: #006666;
  padding: 45px;
}


.checklist-checkbox {
  width: 50px;
  /* Double-sized Checkboxes */
  -ms-transform: scale(1.3);
  /* IE */
  -moz-transform: scale(1.3);
  /* FF */
  -webkit-transform: scale(1.3);
  /* Safari and Chrome */
  -o-transform: scale(1.3);
  /* Opera */
  transform: scale(1.3);
  padding: 10px;
}

// .modal-txn-view {
//     color: white;
// }

.captilize {
  text-transform: capitalize !important;
}

.reactAppMenubar--menu--labelContainer {
  padding: 10px 30px !important;
}

.reactAppMenubar--menu-root {
  font-size: 16px;
}

.navbar-brand {
  font-size: 20px;
  font-weight: 600;
  color: #b8b8b8 !important;
  margin-left: 30px;

  p {
    margin-bottom: -7px;
  }
}

p {
  margin-bottom: 0px;
}

.bg-1 {
  background-color: #003b3a;
}

.bg-2 {
  background-color: #054e4e;
}

.bg-3 {
  background-color: #b0fdfd;
}

.bg-positive {
  background-color: #5ba2a3 !important;
}

.bg-negative {
  background-color: #32cacc !important;
}

.color-white {
  color: #b0fdfd;
}

.color-grey {
  color: #003b3a;
}

.lightbox {
  display: flex;
  width: 100%;
  max-width: 1200px;
  border-radius: 17px;
  height: 600px;
  box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.4);
}

.welcome-box {
  padding: 80px;
  width: 50%;
  border-top-left-radius: 17px;
  border-bottom-left-radius: 17px;
  height: 100%;
}

.fill-height {
  height: 100vh;
}

.t-1 {
  font-size: 24px;
  font-weight: 600;
}

.t-2 {
  font-size: 36px;
  font-weight: 600;
}

.input-1 {
  height: 52px;
  width: 310px;
  background-color: #b0fdfd;
  color: #003b3a;
  margin-top: 10px;
  margin-bottom: 15px;
}

.error-mes {
  position: absolute;
  right: 13px;
  top: 52px;
}

.btn-1 {
  width: 155px;
  height: 52px;
  color: #b0fdfd !important;
  display: flex;
  background-color: #208282 !important;
  border: none !important;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  cursor: pointer;

  p {
    margin-bottom: -7px;
  }
}

.err-border {
  border: 2px solid red !important;
}

textarea {
  height: 130px !important;
}

.error-message2 {
  position: absolute;
  right: 0px;
  bottom: -17px;
}

.disable {
  opacity: .4;
}

.disable-input {
  opacity: .5;
}

.title-row a {
  color: whitesmoke !important;
}

.error-pass-change {
  position: absolute !important;
  right: 0px !important;
  bottom: -18px !important;
}

.btn-2 {
  width: 310px;
  height: 52px;
  color: #b0fdfd;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  margin-bottom: 16px;
  cursor: pointer;

  p {
    margin-bottom: -7px;
  }
}

.pos {
  position: absolute;
  top: 0px;
  right: 25px;
}

.error-pos {
  position: absolute;
  right: 376px;
  bottom: -2px;
}

.t-3 {
  font-size: 14px;
}

a {
  // color: inherit;
  text-decoration: none !important;
}

a:hover {
  // color: inherit;
  text-decoration: none;
}

.overlay {
  background: rgba(61, 51, 51, 0.6);
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 20;
  visibility: visible;
  opacity: 1;
  transition: all 0.6s ease-in;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hidden {
  visibility: hidden;
  opacity: 0;
  transition: all 0.6s ease-in;
}

.popup {
  background-color: #208282;
  box-shadow: 0px 6px 60px 0px rgba(0, 0, 0, 0.2);
  width: 543px;
  height: 383px;
  padding: 48px 90px;
  text-align: center;

  .btn-holder {
    margin-top: 61px;
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .digit-holder {
    margin-top: 50px;
    width: 304px;
    display: flex;
    justify-content: space-between;
  }

  .input-2 {
    background-color: #b0fdfd;
    width: 42px;
    height: 52px;
  }
}

.t-4 {
  font-size: 30px;
  font-weight: 600;
}

h1 {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: -7px;
}

.nav-item {
  margin-left: 16px;
  margin-right: 16px;
}

.body-pad {
  padding: 42px;
}

.stat-row {
  margin-top: 30px;
}

.stat-box {
  background-image: linear-gradient(90deg, rgb(0, 169, 171) 0%, #055a5a);
  width: 100%;
  height: 100px;
  color: white;
  box-shadow: 80px;
  font-weight: 600;
  display: flex;
  padding: 25px;
  justify-content: space-between;
  align-items: center;
  margin: 13px 0px;
  border-radius: 7px;

  .title {
    font-size: 16px;
  }

  .value {
    font-size: 28px;
  }

  p {
    margin-bottom: -5px;
  }
}

.form-holder {
  width: 100%;
  background-color: #31595a;
  margin-top: 25px;
  padding: 45px;
}

h3 {
  font-size: 20px;
  font-weight: 600;
}

.radio-holder {
  width: 250px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  label {
    margin-left: 20px;
    margin-bottom: -4px;
  }
}

.input-holder {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;

  .label {
    font-size: 16px;
    font-weight: 600;
  }

  .input-1 {
    width: 250px;
    height: 42px;
    background-color: #b0fdfd;
    margin: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    color: #006666;

    p {
      margin-left: 20px;
    }

    .input-email {
      height: 30px !important;
    }

    .icon {
      width: 50px;
      height: 42px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .date {
      width: 50px;
      height: 42px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #a5efef;
    }
  }

  .input-2 {
    width: 170px;
    height: 42px;
    background-color: #b0fdfd;
    margin: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    color: #006666;

    p {
      margin-left: 20px;
      margin-bottom: -5px;
    }

    .icon {
      width: 50px;
      height: 42px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #a5efef;
    }
  }

  .multi-input {
    width: 250px;
    height: 42px;
    display: flex;
    justify-content: space-between;

    .input-2 {
      width: 200px;
      height: 42px;
      background-color: #b0fdfd;
    }

    .icon {
      width: 50px;
      height: 42px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.left {
  padding-right: 50px;
}

.right {
  padding-left: 50px;
}

.title-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-content: flex-end;
}

.btn-3 {
  background-color: #208282 !important;
  color: white !important;
  width: 155px;
  height: 52px;
  border: none !important;
  outline: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;

  p {
    margin-bottom: -5px;
  }
}

.hov {
  :hover {
    // background-color: #b0fdfd !important;
    color: rgb(4, 34, 20) !important;
  }
}

.my-table thead th {
  color: #b0fdfd;
  font-size: 16px;
  font-weight: bold;
  border-top: none;
}

.my-table tr td {
  color: #b0fdfd;
  font-size: 16px;
  font-weight: 600;
  border: none;
}

.center {
  text-align: center;
}

.multi-icons {
  display: flex;

  img {
    margin: 0px 6px;
  }
}

.box {
  padding: 10px 15px;
  background-color: #464646;
  margin-bottom: 30px;

  .title {
    font-size: 18px;
    font-weight: 600;
  }
}

.add-btn {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 10px;
}

.btn-4 {
  background-color: #208282;
  width: 155px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    margin-bottom: -5px;
  }
}

.user-header {
  width: 50%;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  color: white;
  cursor: pointer;
}

.selected-option {
  background-color: #003b3a;
}

.setting-title {
  font-size: 20px;
  font-weight: 600;
  margin-top: 40px;
}

.description-box {
  height: 125px !important;
}

.line {
  width: 100%;
  height: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid white;
}

label {
  font-size: 16px;
}

.labels {
  font-size: 13px;
}

.txn-box {
  background-color: #5ba2a3;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  width: 100%;
  height: 104px;
  cursor: pointer;
}

.txn-box:hover {
  background-color: #4e898a;
}

.comment-box {
  width: 250px;
  height: 100px;
  background-color: white;
  font-size: 14px;
  padding: 5px 10px;

  p {
    margin-bottom: 0px !important;
    font-weight: 600;
    color: #006666;
  }
}

.auth-box {
  width: 100%;
  // height: 300px;
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(to top, rgb(0, 105, 106) 0%, rgb(81, 172, 173)100%);
  padding: 30px;
  text-align: center;
  color: white;

  p {
    margin-bottom: 0px;
  }

  .title {
    font-size: 18px;
  }

  .small {
    font-size: 16px;
  }

  .accepted {
    color: #32cd32;
    font-size: 24px;
    font-weight: bold;
  }

  .rejected {
    color: #cd3232;
    font-size: 24px;
    font-weight: bold;
  }

  .pending {
    color: #5432cd;
    font-size: 24px;
    font-weight: bold;
  }

  .comment {
    font-size: 16px;
    font-weight: bold;
  }
}

.position-relative {
  position: relative !important;
  width: 310px;
}

.position-relative-pass {
  position: relative !important;
  width: 250px;
}

.visibility-icon {
  position: absolute;
  right: 13px;
  z-index: 33;
  top: 12px;
  // color: #009999 !important;
  color: #e14eca !important;
}

.visibility-icon-pass {
  position: absolute;
  right: 13px;
  z-index: 33;
  // top: 14px;
  top: 37px;
  // color: #009999 !important;
  color: #e14eca !important;
}

.icon-pass {
  top: 12px !important;
}

.error-labels {
  font-size: 13px;
  color: #f30e0e !important;
  letter-spacing: 1px !important;
}

.react-checkbox-tree {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  font-size: 16px;
}

.error-lab {
  color: #fd1c1c !important;
  font-size: 14px;
  letter-spacing: 1px !important;
  text-shadow: none;
  text-shadow: 0 1px 1px #ce2626;
}

.react-checkbox-tree>ol {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.react-checkbox-tree ol {
  margin: 0;
  padding-left: 0;
  list-style-type: none;
}

.react-checkbox-tree ol ol {
  padding-left: 24px;
}

.react-checkbox-tree button {
  line-height: normal;
  color: inherit;
}

.react-checkbox-tree button:focus {
  outline: none;
}

.react-checkbox-tree button:disabled {
  cursor: not-allowed;
}

.react-checkbox-tree .rct-bare-label {
  cursor: default;
}

.react-checkbox-tree label {
  margin-bottom: 0;
  cursor: pointer;
}

.react-checkbox-tree label:hover {
  background: rgba(51, 51, 204, 0.1);
}

.react-checkbox-tree label:active {
  background: rgba(51, 51, 204, 0.15);
}

.react-checkbox-tree:not(.rct-native-display) input {
  display: none;
}

.react-checkbox-tree.rct-native-display input {
  margin: 0 5px;
}

.react-checkbox-tree .rct-icon {
  display: inline-block;
  text-align: center;
  text-rendering: auto;
  font-family: "Font Awesome 5 Free", "FontAwesome", sans-serif;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
}

.rct-disabled>.rct-text>label {
  opacity: .75;
  cursor: not-allowed;
}

.pointer {
  cursor: pointer;
}

.rct-disabled>.rct-text>label:hover {
  background: transparent;
}

.rct-disabled>.rct-text>label:active {
  background: transparent;
}

.rct-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.rct-options {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  margin-left: .5rem;
  text-align: right;
}

.rct-option {
  opacity: .75;
  border: 0;
  background: none;
  cursor: pointer;
  padding: 0 4px;
  font-size: 18px;
}

.rct-option:hover {
  opacity: 1;
}

.rct-option+.rct-option {
  margin-left: 2px;
}

.rct-collapse,
.rct-checkbox,
.rct-node-icon {
  padding: 0 5px;
}

.rct-collapse *,
.rct-checkbox *,
.rct-node-icon * {
  display: inline-block;
  margin: 0;
  width: 14px;
}

.rct-collapse {
  border: 0;
  background: none;
  line-height: normal;
  color: inherit;
  font-size: 12px;
}

.rct-collapse.rct-collapse-btn {
  cursor: pointer;
}

.rct-collapse>.rct-icon-expand-close {
  opacity: .5;
}

.rct-collapse>.rct-icon-expand-close:hover {
  opacity: 1;
}

.rct-native-display .rct-checkbox {
  display: none;
}

.rct-node-clickable {
  cursor: pointer;
}

.rct-node-clickable:hover {
  background: rgba(51, 51, 204, 0.1);
}

.rct-node-clickable:focus {
  outline: 0;
  background: rgba(51, 51, 204, 0.2);
}

.rct-node-icon {
  color: #33c;
}

.rct-title {
  padding: 0 5px;
}

.rct-icons-fa4 .rct-icon-expand-close::before {
  content: "\f054";
}

.rct-icons-fa4 .rct-icon-expand-open::before {
  content: "\f078";
}

.rct-icons-fa4 .rct-icon-uncheck::before {
  content: "\f096";
}

.rct-icons-fa4 .rct-icon-check::before {
  content: "\f046";
}

.rct-icons-fa4 .rct-icon-half-check::before {
  opacity: .5;
  content: "\f046";
}

.rct-icons-fa4 .rct-icon-leaf::before {
  content: "\f016";
}

.rct-icons-fa4 .rct-icon-parent-open::before {
  content: "\f115";
}

.rct-icons-fa4 .rct-icon-parent-close::before {
  content: "\f114";
}

.rct-icons-fa4 .rct-icon-expand-all::before {
  content: "\f0fe";
}

.rct-icons-fa4 .rct-icon-collapse-all::before {
  content: "\f146";
}

.rct-icons-fa5 .rct-icon-expand-close::before {
  font-weight: 900;
  content: "\f054";
}

.rct-icons-fa5 .rct-icon-expand-open::before {
  font-weight: 900;
  content: "\f078";
}

.rct-icons-fa5 .rct-icon-uncheck::before {
  content: "\f0c8";
}

.rct-icons-fa5 .rct-icon-check::before {
  content: "\f14a";
}

.rct-icons-fa5 .rct-icon-half-check::before {
  opacity: .5;
  content: "\f14a";
}

.rct-icons-fa5 .rct-icon-leaf::before {
  content: "\f15b";
}

.rct-icons-fa5 .rct-icon-parent-open::before {
  content: "\f07c";
}

.rct-icons-fa5 .rct-icon-parent-close::before {
  content: "\f07b";
}

.rct-icons-fa5 .rct-icon-expand-all::before {
  content: "\f0fe";
}

.rct-icons-fa5 .rct-icon-collapse-all::before {
  content: "\f146";
}

.Toastify__toast-container {
  z-index: 9999;
  -webkit-transform: translate3d(0, 0, 9999px);
  transform: translate3d(0, 0, 9999px);
  position: fixed;
  padding: 4px;
  width: 320px;
  box-sizing: border-box;
  color: #fff;
}

.Toastify__toast-container--top-left {
  top: 1em;
  left: 1em;
}

.Toastify__toast-container--top-center {
  top: 1em;
  left: 50%;
  transform: translateX(-50%);
}

.Toastify__toast-container--top-right {
  top: 1em;
  right: 1em;
}

.Toastify__toast-container--bottom-left {
  bottom: 1em;
  left: 1em;
}

.Toastify__toast-container--bottom-center {
  bottom: 1em;
  left: 50%;
  transform: translateX(-50%);
}

.Toastify__toast-container--bottom-right {
  bottom: 1em;
  right: 1em;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    width: 100vw;
    padding: 0;
    left: 0;
    margin: 0;
  }

  .Toastify__toast-container--top-left,
  .Toastify__toast-container--top-center,
  .Toastify__toast-container--top-right {
    top: 0;
    transform: translateX(0);
  }

  .Toastify__toast-container--bottom-left,
  .Toastify__toast-container--bottom-center,
  .Toastify__toast-container--bottom-right {
    bottom: 0;
    transform: translateX(0);
  }

  .Toastify__toast-container--rtl {
    right: 0;
    left: initial;
  }
}

.Toastify__toast {
  position: relative;
  min-height: 64px;
  box-sizing: border-box;
  margin-bottom: 1rem;
  padding: 8px;
  border-radius: 1px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  max-height: 800px;
  overflow: hidden;
  font-family: sans-serif;
  cursor: pointer;
  direction: ltr;
}

.Toastify__toast--rtl {
  direction: rtl;
}

.Toastify__toast--dark {
  background: #121212;
  color: #fff;
}

.Toastify__toast--default {
  background: #fff;
  color: #aaa;
}

.Toastify__toast--info {
  background: #3498db;
}

.Toastify__toast--success {
  background: #07bc0c;
}

.Toastify__toast--warning {
  background: #f1c40f;
}

.Toastify__toast--error {
  background: #e74c3c;
}

.Toastify__toast-body {
  margin: auto 0;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast {
    margin-bottom: 0;
  }
}

.Toastify__close-button {
  color: #fff;
  background: transparent;
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;
  opacity: 0.7;
  transition: 0.3s ease;
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.Toastify__close-button--default {
  color: #000;
  opacity: 0.3;
}

.Toastify__close-button>svg {
  fill: currentColor;
  height: 16px;
  width: 14px;
}

.Toastify__close-button:hover,
.Toastify__close-button:focus {
  opacity: 1;
}

@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1);
  }

  100% {
    transform: scaleX(0);
  }
}

.Toastify__progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  z-index: 9999;
  opacity: 0.7;
  background-color: rgba(255, 255, 255, 0.7);
  transform-origin: left;
}

.Toastify__progress-bar--animated {
  animation: Toastify__trackProgress linear 1 forwards;
}

.Toastify__progress-bar--controlled {
  transition: transform 0.2s;
}

.Toastify__progress-bar--rtl {
  right: 0;
  left: initial;
  transform-origin: right;
}

.Toastify__progress-bar--default {
  background: linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55);
}

.Toastify__progress-bar--dark {
  background: #bb86fc;
}

@keyframes Toastify__bounceInRight {

  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }

  75% {
    transform: translate3d(10px, 0, 0);
  }

  90% {
    transform: translate3d(-5px, 0, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}

@keyframes Toastify__bounceInLeft {

  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }

  75% {
    transform: translate3d(-10px, 0, 0);
  }

  90% {
    transform: translate3d(5px, 0, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}

@keyframes Toastify__bounceInUp {

  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  75% {
    transform: translate3d(0, 10px, 0);
  }

  90% {
    transform: translate3d(0, -5px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0);
  }

  40%,
  45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}

@keyframes Toastify__bounceInDown {

  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }

  75% {
    transform: translate3d(0, -10px, 0);
  }

  90% {
    transform: translate3d(0, 5px, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0);
  }

  40%,
  45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}

.Toastify__bounce-enter--top-left,
.Toastify__bounce-enter--bottom-left {
  animation-name: Toastify__bounceInLeft;
}

.Toastify__bounce-enter--top-right,
.Toastify__bounce-enter--bottom-right {
  animation-name: Toastify__bounceInRight;
}

.Toastify__bounce-enter--top-center {
  animation-name: Toastify__bounceInDown;
}

.Toastify__bounce-enter--bottom-center {
  animation-name: Toastify__bounceInUp;
}

.Toastify__bounce-exit--top-left,
.Toastify__bounce.stat-box {
  background-image: linear-gradient(90deg, rgb(0, 169, 171) 0%, #055a5a);
  width: 100%;
  height: 100px;
  color: white;
  box-shadow: 80px;
  font-weight: 600;
  display: flex;
  padding: 25px;
  justify-content: space-between;
  align-items: center;
  margin: 13px 0px;
  border-radius: 7px;

  .title {
    font-size: 16px;
  }

  .value {
    font-size: 28px;
  }

  p {
    margin-bottom: -5px;
  }
}

.Toastify__bounce-exit--top-right,
.Toastify__bounce-exit--bottom-right {
  animation-name: Toastify__bounceOutRight;
}

.Toastify__bounce-exit--top-center {
  animation-name: Toastify__bounceOutUp;
}

.Toastify__bounce-exit--bottom-center {
  animation-name: Toastify__bounceOutDown;
}

@keyframes Toastify__zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes Toastify__zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  to {
    opacity: 0;
  }
}

.Toastify__zoom-enter {
  animation-name: Toastify__zoomIn;
}

.Toastify__zoom-exit {
  animation-name: Toastify__zoomOut;
}

@keyframes Toastify__flipIn {
  from {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0;
  }

  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    animation-timing-function: ease-in;
  }

  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }

  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }

  to {
    transform: perspective(400px);
  }
}

@keyframes Toastify__flipOut {
  from {
    transform: perspective(400px);
  }

  30% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }

  to {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}

.Toastify__flip-enter {
  animation-name: Toastify__flipIn;
}

.Toastify__flip-exit {
  animation-name: Toastify__flipOut;
}

@keyframes Toastify__slideInRight {
  from {
    transform: translate3d(110%, 0, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideInLeft {
  from {
    transform: translate3d(-110%, 0, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideInUp {
  from {
    transform: translate3d(0, 110%, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideInDown {
  from {
    transform: translate3d(0, -110%, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideOutRight {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(110%, 0, 0);
  }
}

@keyframes Toastify__slideOutLeft {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(-110%, 0, 0);
  }
}

@keyframes Toastify__slideOutDown {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, 500px, 0);
  }
}

@keyframes Toastify__slideOutUp {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, -500px, 0);
  }
}

.Toastify__slide-enter--top-left,
.Toastify__slide-enter--bottom-left {
  animation-name: Toastify__slideInLeft;
}

.Toastify__slide-enter--top-right,
.Toastify__slide-enter--bottom-right {
  animation-name: Toastify__slideInRight;
}

.Toastify__slide-enter--top-center {
  animation-name: Toastify__slideInDown;
}

.Toastify__slide-enter--bottom-center {
  animation-name: Toastify__slideInUp;
}

.Toastify__slide-exit--top-left,
.Toastify__slide-exit--bottom-left {
  animation-name: Toastify__slideOutLeft;
}

.Toastify__slide-exit--top-right,
.Toastify__slide-exit--bottom-right {
  animation-name: Toastify__slideOutRight;
}

.Toastify__slide-exit--top-center {
  animation-name: Toastify__slideOutUp;
}

.Toastify__slide-exit--bottom-center {
  animation-name: Toastify__slideOutDown;
}


/*# sourceMappingURL=ReactToastify.css.map */

.pagination li {
  background-color: #009999;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5%;
}

.pagination {
  float: right;
}

.number-text {
  position: absolute;
  left: 8%;
  top: 2%;
  border: 1px solid;
  border-radius: 50%;
  font-size: 20px;
  height: 30px;
  width: 30px;
  text-align: center;
}

// View Money Market Security Show table in vertical form
.transform {
  display: -ms-grid;
  -ms-grid-rows: auto auto;
  -ms-grid-columns: auto auto;
}

.transform thead {
  -ms-grid-row: 2;
  -ms-grid-column: 1;
}

.transform tbody {
  -ms-grid-row: 2;
  -ms-grid-column: 2;
}

.transform caption {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
}

// Everyone Else's Grid 
@supports (display: grid) {
  .transform {
    display: grid;
    grid-template-columns: min-content min-content;
    grid-template-rows: auto auto;
    grid-template-areas:
      "caption caption"
      "head body";
  }

  .transform thead {
    grid-area: head;
  }

  .transform tbody {
    grid-area: body;
  }

  // .transform caption {
  //   grid-area: caption;
  // }
}

//Flex - Cross Browser CSS /
.transform thead {
  display: flex;
  flex-shrink: 0;
  min-width: min-content;
}

.transform tbody {
  display: flex;
}

.transform tr {
  display: flex;
  flex-direction: column;
  min-width: min-content;
  font-size: 10px;
  color: rgba(255, 255, 255, 0.7);
  flex-shrink: 0;
}

.transform th {
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 500 !important;
  width: 180px;
  height: 40px;
  color: rgba(255, 255, 255, 0.7);
}

.transform td {
  width: 150px;
  height: 40px;
}

.transform td,
.transform th {
  display: block;
  color: rgba(255, 255, 255, 0.7);
}

.transform caption {
  display: block;
}

// Visual styling /
.transform {
  border-collapse: collapse;
}

.transform td {
  border-top: none !important;
}

.transform th {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
  border-right: 1px solid rgba(255, 255, 255, 0.1) !important;
  line-height: 16px;
}

.transform td {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
}

// Start Calculator Fixed on Right Side
.calculator {
  width: 250px;
  background-color: #1E1E2F;
  /* border: 1px solid #1E1E2F; */ 
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  // margin: 20px 20px -5px -20px;
  margin-right: 10 auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
  min-height: 40vh;
  /* max-height: 60vh;
  /* overflow-y: auto;
 overflow-x: hidden; */
}
.calculator .output1{
  color: #ffffff;
}
.scrollable1-div {
  overflow-y: scroll;
  height: 200px; /* set a fixed height for the div */
  // direction: rtl; /* set the text direction to right-to-left */
  display: flex;
  flex-direction: column; /* reverse the order of the content */
}
// end Calculator Fixed on Right Side